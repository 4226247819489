<template>
  <div class="home page">
    <navbar />
    <div class="box">
      <div class="home-content">
        <div class="block">
          <div class="block-title">
            操作指引
          </div>
          <div class="block-content about">
            <ol class="block-jieshao">
              <li>1、注册与登录：直接用手机号码注册即可登录；</li>
              <li>2、忘记密码：用注册时所填写的手机号进行密码找回；</li>
              <li>
                3、搜索：支持搜索家谱名称（题名）、编号、堂号、字辈、简介、始祖、编撰者、谱籍地、发源地等等；
              </li>
              <li>4、会员：注册即成为会员；</li>
              <li>
                5、VIP会员主要权益：
                <ol>
                  <li>①发布谱书和内容并获取收益的权益；</li>
                  <li>②分享推广并获取收益的权益。</li>
                </ol>
              </li>
              <li>6、如何升级会员权限：补齐差额即可升级成为更高级别会员；</li>
              <li>7、其他问题咨询：19974933717</li>
            </ol>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import otherApis from "@/apis/other";
import { Swipe, SwipeItem } from "vant";
import Navbar from "@/components/h5/Navbar";

Vue.use(Swipe);
Vue.use(SwipeItem);

export default {
  name: "Home",
  components: {
    Navbar
  },
  data() {
    return {
      banner: [],
      aboutImgSrc: ""
    };
  },
  created() {
    this.fetchBanner();
  },
  methods: {
    async fetchBanner() {
      console.log("fetch banner");
      const {
        data: { banners }
      } = await otherApis.banner();

      this.banner = banners;
      // this.aboutImgSrc = img;
      console.log(banners);
    }
  }
};
</script>

<style lang="scss" scoped>
.page {
  width: 1125px;
  // height: 4990px;
  // padding-bottom: 100px;
  background-color: #ffffff;
}

.box {
  background-size: contain;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-direction: column;
  // background-color: #fdf1e8;
  // background-image: url(./images/img_65757_1_0.png);
  // background: linear-gradient(0deg, #fcead9 0%, #fdf2ea 100%);
  overflow: hidden;
}

.home-content {
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-direction: column;
  margin-top: 30px;
  padding: 0 40px;
  box-sizing: border-box;
  // background-color: #ffffff;
  width: 1125px;
  overflow: hidden;

  .block {
    width: 100%;
    border-bottom: 1px solid #efefef;
    margin-top: 60px;
    padding-bottom: 60px;

    .block-title {
      text-align: center;
      color: #8c4134;
      font-size: 60px;
      display: flex;
      justify-content: center;
      align-items: center;

      &::before {
        content: "";
        display: block;
        margin-right: 34px;
        background-color: #CA0A15;
        width: 50px;
        height: 6px;
        overflow: hidden;
      }

      &::after {
        content: "";
        display: block;
        margin-left: 34px;
        background-color: #CA0A15;
        width: 50px;
        height: 6px;
        overflow: hidden;
      }
    }

    .block-content {
    }
  }

  .about {
    .about-image {
      position: relative;
      align-self: center;
      margin-top: 62px;
      border-radius: 10px;
      // background-color: #fdbd9b;
      width: 1044px;
      height: 700px;
      overflow: hidden;

      img {
        height: 100%;
        width: 100%;
        object-fit: cover;
      }
    }

    .about-text {
      color: #333333;
      line-height: 72px;
      font-size: 44px;
      text-indent: 2em;
    }
  }
}

.block-jieshao {
  margin: 0 auto;
  padding: initial;
  margin-top: 31px;
  border-radius: 4px;
  // background-color: #f7f7f7;
  // width: 1160px;
  // height: 300px;
  font-size: 44px;
  line-height: 2;
  overflow: hidden;
  list-style: decimal;
  // list-style: decimal-leading-zero;
  // list-style: nt;
  li {
    padding: initial;

    // padding-left: 1em;
    list-style: decimal;
    // text-indent: 1em !important;
  }

  ol {
    text-indent: 1em;
  }
}
</style>
